import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { UserId, BookId } from "../handlers/globals";
import { Defaults, QueryCase, QueryId, ToastId } from "../handlers/enums";
import { Button, Card, Divider, Image, Popconfirm, Popover, Rate, Spin, Tag, Tooltip } from "antd";
import ComponentState from "./components/states";
import { GetData, MutateData } from "../handlers/axios";
import dayjs from "dayjs";
import { useState } from "react";
import Icon, { PushpinOutlined, PushpinFilled, TagOutlined, FlagOutlined, LockTwoTone, BarcodeOutlined, DeleteOutlined, ShareAltOutlined, FlagFilled, EditOutlined, SunOutlined, MoonOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import TextTruncate from "react-text-truncate";
import GooglePlayBooksIcon from "../assets/sprites/GooglePlayBooks.png";
import { IconHeart, IconFeather, IconPepper, IconTear, IconStar } from "../assets/sprites/CustomIcons";
import { Input } from "antd";
import { notifyError, notifySuccess } from "../handlers/extensions";
import { useSearchParams } from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";
import ComponentBookIcon from "./components/bookicon";
import ComponentDateTimePicker from "./components/datetimepicker";

export function SectionBook(props) {
    const [bookId,] = useAtom(BookId);
    const [searchParams,] = useSearchParams();

    return (!bookId || bookId === Defaults.GuidEmpty ? !searchParams.get("id") ? <ComponentState error /> : <BookLayer bookId={searchParams.get("id")} /> : <>
        <BookLayer bookId={bookId} />
    </>);
}

function BookLayer(props) {
    const [userId,] = useAtom(UserId);
    const [, setBookId] = useAtom(BookId);
    setBookId(props.bookId);

    const response = GetData(QueryId.SelectBook, { UserId: userId, BookId: props.bookId });

    return (response.isLoading ? <Spin size="large" className="mx-auto w-full mt-5" /> : response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty /> : <>
        <Book book={response.data} refetch={response.refetch} />
    </>);
}

function Book(props) {
    const { t } = useTranslation();

    const [userId,] = useAtom(UserId);

    const [freezeActions, setFreezeActions] = useState(false);

    const [isCompleted, setIsCompleted] = useState(props.book.completedOn ? true : false);

    const [fullDescription, setFullDescription] = useState(false);

    const actionBookmark = MutateData(QueryId.ToggleUserBook,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); } }
    ]);

    const onBookmark = (properties) => {
        properties = {};
        properties.UserId = userId;
        properties.BookId = props.book.bookId;

        setFreezeActions(true);
        actionBookmark.mutate(properties);
    };

    const actionDelete = MutateData(QueryId.ToggleUserBook,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); } }]);

    const onDelete = (properties) => {
        properties = {};
        properties.UserId = userId;
        properties.BookId = props.book.bookId;

        setFreezeActions(true);
        actionDelete.mutate(properties);
    };

    const actionToggleComplete = MutateData(QueryId.ToggleUserBookComplete,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); setIsCompleted(props.book.completedOn); } }
    ]);

    const onToggleComplete = (properties) => {
        properties = {};
        properties.UserId = userId;
        properties.BookId = props.book.bookId;

        setFreezeActions(true);
        actionToggleComplete.mutate(properties);
    };

    const actionRate = MutateData(QueryId.UpdateUserBookRating,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onRate = (properties) => {
        properties.UserId = userId;
        properties.BookId = props.book.bookId;

        setFreezeActions(true);
        actionRate.mutate(properties);
    };

    const actionFavoChars = MutateData(QueryId.UpdateUserBookCharacters,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onSaveFavoChars = (properties) => {
        properties = {};
        properties.UserId = userId;
        properties.BookId = props.book.bookId;
        properties.Text = favoChars;

        setFreezeActions(true);
        actionFavoChars.mutate(properties);
    };

    const actionNotes = MutateData(QueryId.UpdateUserBookNotes,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onSaveNotes = (properties) => {
        properties = {};
        properties.UserId = userId;
        properties.BookId = props.book.bookId;
        properties.Text = notes;

        setFreezeActions(true);
        actionNotes.mutate(properties);
    };

    const [favoChars, setFavoChars] = useState(Defaults.StringEmpty);
    const [notes, setNotes] = useState(Defaults.StringEmpty);

    const { TextArea } = Input;

    return (<>
        <div className="pb-3">
            <ComponentBookIcon book={props.book} />
        </div>
        <div className="text-custom-white text-center mt-2">
            {!props.book.completedOn ? null :
                <Tag color="#87d068" className="mb-4">{t("completed_on")} {dayjs(props.book.completedOn).format("DD-MM-YYYY")}</Tag>
            }
            <div className="flex justify-center">
                <p className="font-semibold text-xl mr-2">{props.book.title}</p>
                <a className="my-auto" href={props.book.linkPreview} rel="noreferrer noopener" target="_blank">
                    <Image width={32} height={32} className="min-w-[32px] max-h-[32px] rounded-lg hover:hue-rotate-60" src={GooglePlayBooksIcon} preview={false} />
                </a>
            </div>
            <p className="text-lg">{props.book.subtitle}</p>
            <p className="text-sm mb-1">{t("by_author")} {props.book.authors}</p>
            <p className="text-xs">{dayjs(props.book.publishedOn).format("YYYY")} - {props.book.publisher}</p>
            <p className="text-xs my-1">{props.book.categories}</p>
            <div className="flex justify-center">
                <p className="text-xs">{t("page_amount")} {props.book.pageCount === 0 ? t("unknown") : props.book.pageCount}</p>
                <Popover content={<Paragraph copyable={{ /*icon: [<ShareAltOutlined className="text-2xl" />, <CheckOutlined className="text-2xl" />],*/ tooltips: [t("copy"), t("copied")], text: props.book.isbn }}><span className="font-semibold">{t("isbn")}:</span> {props.book.isbn}</Paragraph>}>
                    <BarcodeOutlined className="ml-2 text-lg text-custom-yellow" />
                </Popover>
            </div>
            {!props.book.description ? null : <TextTruncate containerClassName="text-sm pt-2 book-description"
                line={fullDescription ? 9999 : 2}
                truncateText="…"
                text={props.book.description}
                textTruncateChild={<Button className="border-none text-sm p-0 !bg-transparent" onClick={() => { setFullDescription(!fullDescription); }} size={"large"}><div className="underline hover:text-custom-white text-custom-antdesign_blue">{t("read_more")}</div></Button>
                }
            />}
            {fullDescription ? <Button className="border-none text-sm p-0 !bg-transparent" onClick={() => { window.scrollTo(0, 0); setFullDescription(!fullDescription); }} size={"large"}><div className="underline hover:text-custom-white text-custom-antdesign_blue">{t("read_less")}</div></Button> : null}
        </div>
        <div className="mt-4">
            <Button onClick={() => { notifySuccess("toast_copied"); navigator.clipboard.writeText(window.location.href); }} disabled={freezeActions} className="bg-[#3b82f6aa] disabled:bg-[#3b82f6aa] w-full" icon={<ShareAltOutlined />} size={"large"}>{t("share")}</Button>
            {!props.book.retailPrice || !props.book.linkPurchase ? null :
                <a className="my-auto" href={props.book.linkPurchase} rel="noreferrer noopener" target="_blank">
                    <Button disabled={freezeActions} className="bg-[#3b82f6aa] disabled:bg-[#3b82f6aa] w-full mt-3" icon={<TagOutlined />} size={"large"}>{t("buy")} {props.book.retailPrice}&#8364;</Button>
                </a>
            }
            {!userId ? null : !props.book.userBookId
                ? <Button disabled={freezeActions} className="bg-[#3b82f6aa] disabled:bg-[#3b82f6aa] w-full mt-3" onClick={() => { onBookmark({}); }} icon={<PushpinOutlined />} size={"large"}>{t("add_tbr")}</Button>
                : <Popconfirm title={t("confirm_delete_book_title")} description={t("confirm_delete_book_description")}
                    onConfirm={() => { onDelete({}); }}
                    okText={t("yes")} cancelText={t("no")}
                    okButtonProps={{ className: "!border-none hover:!bg-custom-orange" }}
                    cancelButtonProps={{ className: "hover:!text-custom-white" }}>
                    <Button disabled={freezeActions} className="border-none !bg-custom-red disabled:!bg-custom-red active:!bg-custom-red w-full mt-3" icon={<PushpinFilled />} size={"large"}>{t("delete_tbr")}</Button>
                </Popconfirm>
            }
            {!userId ? null : !isCompleted
                ? <Popconfirm title={t("confirm_complete_book_title")} description={t("confirm_complete_book_description")}
                    onConfirm={() => { setIsCompleted(true); onToggleComplete({}); }}
                    okText={t("yes")} cancelText={t("no")}
                    okButtonProps={{ className: "!border-none hover:!bg-custom-orange" }}
                    cancelButtonProps={{ className: "hover:!text-custom-white" }}>
                    <Button disabled={freezeActions} className="bg-[#3b82f6aa] disabled:bg-[#3b82f6aa] active:bg-[#3b82f6aa] w-full mt-3" icon={<FlagOutlined />} size={"large"}>{t("complete")}</Button>
                </Popconfirm>
                : <Popconfirm title={t("confirm_uncomplete_book_title")} description={t("confirm_uncomplete_book_description")}
                    onConfirm={() => { setIsCompleted(false); onToggleComplete({}); }}
                    okText={t("yes")} cancelText={t("no")}
                    okButtonProps={{ className: "!border-none hover:!bg-custom-orange" }}
                    cancelButtonProps={{ className: "hover:!text-custom-white" }}>
                    <Button disabled={freezeActions} className="border-none !bg-custom-red disabled:!bg-custom-red active:!bg-custom-red w-full mt-3" icon={<FlagFilled />} size={"large"}>{t("uncomplete")}</Button>
                </Popconfirm>
            }
            <Divider className="bg-custom-darkgrey" />
            {!userId ? null : <>
                <Sessions book={props.book} isCompleted={isCompleted} />
                <Divider className="bg-custom-darkgrey" />
                <div className="text-custom-white">
                    <p className="font-semibold text-lg mb-5">{t("rate")}{isCompleted ? null : <Tooltip title={t("tooltip_ratinglock")}>
                        <LockTwoTone className="text-xl ml-2" twoToneColor={"#ff0000"} />
                    </Tooltip>
                    }</p>
                    <div className="flex flex-col gap-3">
                        <div className="flex justify-between">
                            <p className="font-semibold">{t("suspense")}</p>
                            <Rate defaultValue={props.book.ratingSuspense} disabled={!isCompleted} onChange={(event) => { onRate({ RatingType: "Suspense", Rating: event }); }} className="rate-bg-32 rate-bg-light rate-bg-checked-red" character={<Icon className="w-8 h-8" component={IconHeart} />} />
                        </div>
                        <div className="flex justify-between">
                            <p className="font-semibold">{t("tear")}</p>
                            <Rate defaultValue={props.book.ratingTears} disabled={!isCompleted} onChange={(event) => { onRate({ RatingType: "Tears", Rating: event }); }} className="rate-bg-32 rate-bg-light rate-bg-checked-blue" character={<Icon className="w-8 h-8" component={IconTear} />} />
                        </div>
                        <div className="flex justify-between">
                            <p className="font-semibold">{t("spicy")}</p>
                            <Rate defaultValue={props.book.ratingSpiciness} disabled={!isCompleted} onChange={(event) => { onRate({ RatingType: "Spiciness", Rating: event }); }} className="rate-bg-32 rate-bg-light rate-bg-checked-green" character={<Icon className="w-8 h-8" component={IconPepper} />} />
                        </div>
                        <div className="flex justify-between">
                            <p className="font-semibold">{t("story")}</p>
                            <Rate defaultValue={props.book.ratingStory} disabled={!isCompleted} onChange={(event) => { onRate({ RatingType: "Story", Rating: event }); }} className="rate-bg-32 rate-bg-light rate-bg-checked-white" character={<Icon className="w-8 h-8" component={IconFeather} />} />
                        </div>
                        <div className="flex justify-between">
                            <p className="font-semibold">{t("overall")}</p>
                            <Rate defaultValue={props.book.ratingOverall} disabled={!isCompleted} onChange={(event) => { onRate({ RatingType: "Overall", Rating: event }); }} className="rate-bg-32 rate-bg-light rate-bg-checked-yellow" character={<Icon className="w-8 h-8" component={IconStar} />} />
                        </div>
                    </div>
                    <div className="flex justify-between mt-3">
                        <p className="font-semibold text-wrap max-w-[25%] mr-5">{t("favo_chars")}</p>
                        <TextArea defaultValue={props.book.favoriteCharacters} onBlur={() => { onSaveFavoChars(); }} onChange={(event) => { setFavoChars(event.target.value); }} variant={isCompleted ? null : "borderless"} readOnly={!isCompleted} placeholder={!isCompleted ? null : t("placeholder_favo_chars")} className="mb-3 custom-color-placeholder" showCount={isCompleted} maxLength={1000} autoSize={{ minRows: 2, maxRows: 10 }} />
                    </div>
                    <div className="flex justify-between mt-3">
                        <p className="font-semibold text-wrap max-w-[25%] mr-5">{t("notes")}</p>
                        <TextArea defaultValue={props.book.notes} onBlur={() => { onSaveNotes(); }} onChange={(event) => { setNotes(event.target.value); }} variant={isCompleted ? null : "borderless"} readOnly={!isCompleted} placeholder={!isCompleted ? null : t("placeholder_notes")} className="mb-3 custom-color-placeholder" showCount={isCompleted} maxLength={1000} autoSize={{ minRows: 2, maxRows: 10 }} />
                    </div>
                    <Quotes />
                </div>
                <Divider className="bg-custom-darkgrey" />
            </>}
            {/* <Button type="primary" disabled={freezeActions} className="bg-[#3b82f6aa] disabled:bg-[#3b82f6aa] w-full mb-2" onClick={() => { onBookmark({}); }} icon={<PushpinOutlined />} size={"large"}> More information</Button> */}
        </div>
    </>);
}

function Sessions(props) {
    const { t } = useTranslation();

    const [userId,] = useAtom(UserId);
    const [bookId,] = useAtom(BookId);

    const [freezeActions, setFreezeActions] = useState(false);

    const response = GetData(QueryId.SelectUserBookSessions, { UserId: userId, BookId: props.book.bookId });

    const actionAddSession = MutateData(QueryId.InsertUserBookSession,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { response.refetch(); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onAdd = (properties) => {
        properties = {};
        properties.BookId = bookId;
        properties.UserId = userId;

        setFreezeActions(true);
        actionAddSession.mutate(properties);
    }

    return (<>
        <Card className="card-sessions border-none bg-none" title={t("reading_sessions")} extra={props.isCompleted || response.isLoading || response.isFalse || response.data?.some((session) => { return !session.endedOn; }) ? null : <Button disabled={freezeActions} type="primary" shape="circle" className="bg-custom-orange disabled:bg-custom-orange" icon={<PlusOutlined onClick={() => { onAdd({}); }} />} />
        }>
            {props.isCompleted ? <p>{t("sessions_empty")}</p> :
                <div className="flex flex-col gap-2">
                    {response.isLoading ? <Spin size="large" /> : response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty description={t("empty_sessions")} /> : response.data?.map((session) => {
                        return <div key={session.id}>
                            <Session session={session} />
                        </div>
                    })}
                </div>
            }
        </Card>
    </>);
}

function Session(props) {
    const { t } = useTranslation();
    const [userId,] = useAtom(UserId);
    const [bookId,] = useAtom(BookId);

    const [freezeActions, setFreezeActions] = useState(false);

    const [hidden, setHidden] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [startedOn, setStartedOn] = useState(props.session?.startedOn);
    const [endedOn, setEndedOn] = useState(props.session?.endedOn);

    const actionAdjustSession = MutateData(QueryId.UpdateUserBookSession,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onFinish = (properties) => {
        properties = {};
        properties.StartedOn = startedOn;
        properties.EndedOn = endedOn;
        properties.SessionId = props.session.id;
        properties.BookId = bookId;
        properties.UserId = userId;

        setFreezeActions(true);
        actionAdjustSession.mutate(properties);
    }

    const actionDeleteSession = MutateData(QueryId.DeleteUserBookSession,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); setHidden(false); } }
    ]);

    const onDelete = (properties) => {
        properties = {};
        properties.SessionId = props.session.id;
        properties.BookId = bookId;
        properties.UserId = userId;

        setFreezeActions(true);
        actionDeleteSession.mutate(properties);
    }

    const [startOpen, setStartOpen] = useState(false);
    const [endOpen, setEndOpen] = useState(false);

    return (hidden ? null : isEditing ? <div className="flex gap-2 justify-between">
        <div>
            <ComponentDateTimePicker open={startOpen} cancel={() => { setStartOpen(false); }} confirm={(event) => { setStartOpen(false); setStartedOn(event); }} />
            <Input suffix={<Button disabled={freezeActions} type="primary" shape="circle" className="bg-custom-orange disabled:bg-custom-orange" icon={<EditOutlined onClick={() => { setStartOpen(true); }} />} />
            } readOnly className="mb-2 custom-color-placeholder" prefix={<SunOutlined className="mr-1" />} placeholder={Defaults.DateTimeFormatEurope} value={startedOn ? dayjs(startedOn).format(Defaults.DateTimeFormatEurope) : Defaults.StringEmpty} allowClear={true} />
            <ComponentDateTimePicker open={endOpen} cancel={() => { setEndOpen(false); }} confirm={(event) => { setEndOpen(false); setEndedOn(event); }} />
            <Input suffix={<Button disabled={freezeActions} type="primary" shape="circle" className="bg-custom-orange disabled:bg-custom-orange" icon={<EditOutlined onClick={() => { setEndOpen(true); }} />} />
            } readOnly className="custom-color-placeholder" prefix={<MoonOutlined className="mr-1" />} placeholder={Defaults.DateTimeFormatEurope} value={endedOn ? dayjs(endedOn).format(Defaults.DateTimeFormatEurope) : Defaults.StringEmpty} allowClear={true} />
        </div>
        <div className="flex flex-col gap-2">
            <Button disabled={freezeActions} type="primary" shape="circle" className="bg-custom-orange disabled:bg-custom-orange" icon={<CloseOutlined onClick={() => { onFinish({}); setIsEditing(!isEditing); }} />} />
            <Popconfirm title={t("confirm_delete_book_session")} description={t("confirm_delete_book_session_description")}
                onConfirm={() => { onDelete({}); setHidden(true); setIsEditing(!isEditing); }}
                okText={t("yes")} cancelText={t("no")}
                okButtonProps={{ className: "!border-none hover:!bg-custom-orange" }}
                cancelButtonProps={{ className: "hover:!text-custom-white" }}>
                <Button disabled={freezeActions} type="primary" shape="circle" className="border-none !bg-custom-red disabled:!bg-custom-red" icon={<DeleteOutlined />} />
            </Popconfirm>
        </div>
    </div> : <div className="flex gap-2 justify-between">
        <p className="my-auto text-custom-white"><SunOutlined className="mr-1" />{startedOn ? dayjs(startedOn).format(Defaults.DateTimeFormatEurope) : "???"}</p>
        <p className="my-auto text-custom-white"><MoonOutlined className="mr-1" />{endedOn ? dayjs(endedOn).format(Defaults.DateTimeFormatEurope) : "???"}</p>
        <Button disabled={freezeActions} type="primary" shape="circle" className="bg-custom-orange disabled:bg-custom-orange" icon={<EditOutlined onClick={() => { setIsEditing(!isEditing); }} />} />
    </div>);
}

function Quotes(props) {
    // const { t } = useTranslation();

    //GET QUOTES

    return (<span className="text-2xs">
        Quotes (coming soon)
    </span>);
}